import styled from 'styled-components'
import donnotfound from './../assets/donnotfound.jpg'
import vjlink from './../assets/vjlink.png'
import { motion } from 'framer-motion'

const CardBody = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: #fff;
  width: 100%;
  height: 20rem;
`

const CardOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0rem;
  width: 100%;
`

const Title = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: -0.03em;
  color: #000;
`

const Image = styled(motion.div)`
  background-image: url(${props => props.src});
  background-size: cover;
  width: 20rem;
  height: 10rem;
  margin-top: 1.5625rem; 
  background-position: 0px -19px;
  background-repeat: no-repeat;
`

const CardEmpty = ({ style, isFavorite }) => {
  return (
    <CardBody style={style}>
      <CardOverflow>
        <Title>{isFavorite ? '= В избранном ничего нет =' : '= Ничего не найдено ='}</Title>
        <Image 
          whileTap={{ scaleY: 0.5 }}
          style={{ 
            backgroundPosition: isFavorite ? '0px 0px' : '0px -19px' 
          }} 
          src={isFavorite ? vjlink : donnotfound}  
        />
      </CardOverflow>
    </CardBody>
  )
}

export default CardEmpty