import styled from 'styled-components'
import { observer } from 'mobx-react'
import useStore from './../store'
import { URL } from './../env.js'
import favoriteIcon from './../assets/favorite-card.svg'
import { motion } from 'framer-motion'
import { useState } from 'react'
import numeral from 'numeral'

const CardBody = styled(motion.div)`
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: #fff;
  width: 100%;
  max-width: 22.5rem;
`

const CardOverflow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0rem;
  width: 100%;
`

const CardImage = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
  display: inline-block;
  width: 100%;
  height: 15rem;
`

const CardInfoBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.62rem;
  padding: 0.75rem 0.94rem 1.12rem 0.94rem;
`

const CardInfoOverflow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.12rem;
`

const TitleOverflow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
`

const Title = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: -0.03em;
  color: #000;
`

const PriceOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.75rem;
`

const Price = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #3b3b3b;
`

const TagsOverlowPadding = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1.38rem;
  padding: 0.38rem 0rem 0rem 0rem;
`

const TagsOverlow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.38rem;
` 

const TagBody = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0rem;
  border-radius: 0.38rem;
  padding: 0.31rem 0.56rem;
  background: ${props => props.color};
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 0.81rem;
  letter-spacing: -0.03em;
  color: #fff;
  cursor: pointer;
`

const Favorite = styled.img`
  width: 0.7rem;
  height: 0.7rem;
  margin-left: 0.6rem;
  transform: scale(1.7);
`

const Card = observer(({ card: { images, title, price, colors, variants, tags, id, owner } }) => {
  const store = useStore()

  const image = `${URL}/assets/${images[0]}`

  const [tagState, setTagState] = useState(false)

  const handleTagPrevent = e => {
    if (e.target.getAttribute('name') === 'tag') {
      e.stopPropagation()
      e.preventDefault()
      setTagState(true)
    } else {
      setTagState(false)
    }
  }

  return (
    <CardBody 
      onClick={
        () => {
          store.card = id
          store.navigation = 0
          store.q = ''
        }
      }
      whileTap={tagState ? ({}) : ({ scale: 0.9 })}
    >
      <CardOverflow>
        <CardImage src={image} />
        <CardInfoBody>
          <CardInfoOverflow>
            <TitleOverflow>
              <Title>
                {title}
                {
                  store.favorite.find(_id => id === _id) 
                    ? (
                      <Favorite draggable={false} src={favoriteIcon} />
                    ) 
                    : null
                }
              </Title>
            </TitleOverflow>
            <PriceOverflow>
              <Price>{numeral(parseInt(price) + parseInt(colors[0][1]) + parseInt(variants[0][1])).format('0,0').replace(/,/gi, ' ').trim()} ₽</Price>
            </PriceOverflow>
            <TagsOverlowPadding>
              <TagsOverlow>
                {
                  Object.values(
                    tags
                      .reduce((ctx, elem) => {
                        if (elem.character) {
                          if (!ctx.isCharacter) {
                            ctx.data[elem.tag] = elem
                            ctx.isCharacter = true
                          }
                        } else {
                          ctx.data[elem.tag] = elem
                        }

                        return ctx
                      }, { data: {}, isCharacter: false })
                      .data
                  )
                    .slice(0, 3)
                    .map(
                      ({ tag, color }, key) => (
                        <TagBody
                          whileTap={{ scale: 0.9 }}
                          name='tag' 
                          onMouseDown={e => handleTagPrevent(e)}
                          onTouchStart={e => handleTagPrevent(e)}
                          onClick={e => {
                            if (e.target.getAttribute('name') === 'tag') {
                              e.stopPropagation()
                              e.preventDefault()
                              store.q = `#${tag}`
                              setTagState(true)
                            } else {
                              setTagState(false)
                            }
                          }}
                          color={color} 
                          key={key}
                        >
                          #{tag}
                        </TagBody>
                      )
                    )
                }
              </TagsOverlow>
            </TagsOverlowPadding>
          </CardInfoOverflow>
        </CardInfoBody>
      </CardOverflow>
    </CardBody>
  )
})

export default Card