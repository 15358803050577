import styled from 'styled-components'
import arrowTop from './../assets/arrow-top.svg'
import cart from './../assets/cart.svg'
import { observer } from 'mobx-react'
import useStore from './../store'
import favoritesBlack from './../assets/favorites-black.svg'
import trash from './../assets/trash.svg'
import { motion } from 'framer-motion'

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.56rem;
`

const ButtonOverflow = styled(motion.div)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  padding: 1.19rem 1.25rem;
  width: 6.12rem;
  min-height: 4.12rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: #fff;
  box-sizing: border-box;
`

const Text = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: -0.03em;
  color: #000;
`

const Icon = styled.img`
  width: 1.9rem;
  height: 1.9rem;
`

const Right = observer(() => {
  const store = useStore()

  return (
    <Body>
      <ButtonOverflow 
        whileTap={{ scale: 0.9 }}
        onClick={() => {
          store.navigation === 3 
            ? store.navigation = 0 
            : store.navigation = 3
          
          store.card = 0
        }}
        style={store.navigation === 3 ? { filter: 'invert(1)' } : {}}
      >
        <Icon draggable={false} src={cart} />
        {
          store.cart.length !== 0 
            ? (
              <Text>{store.cart.length}</Text>
            )
            : (
              null
            )
        }
      </ButtonOverflow>
      {
        store.cart.find(id => id === store.card) 
          ? (
            <ButtonOverflow 
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                const index = store.cart.findIndex(id => id === store.card)
                
                if (index !== -1) {
                  store.cart = store.cart.filter((_, _index) => index !== _index)
                } else {
                  store.cart = [
                    ...store.cart.filter(id => store.card !== id), 
                    store.card
                  ]
                }
              }}
            >
              <Icon draggable={false} src={trash} />
            </ButtonOverflow>
          )
          : (
            null
          )
      }
      {
        store.card 
          ? (
            <ButtonOverflow 
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                const index = store.favorite.findIndex(id => id === store.card)
                
                if (index !== -1) {
                  store.favorite = store.favorite.filter(id => store.card !== id)
                } else {
                  store.favorite = [
                    ...store.favorite.filter(id => store.card !== id), 
                    store.card
                  ]
                }
              }}
              style={!!store.favorite.find(id => store.card === id) ? { filter: 'invert(1)' } : {}}
            >
              <Icon draggable={false} src={favoritesBlack} />
            </ButtonOverflow>
          )
          : (
            null
          )
      }
      <ButtonOverflow
        whileTap={{ scale: 0.9 }}
        onClick={() => window.scrollTo(0, 0)}
        style={{
          position: 'fixed',
          bottom: '20px'
        }}
      >
        <Icon draggable={false} src={arrowTop} />
      </ButtonOverflow>
    </Body>
  )
})

export default Right