import { useEffect, useState } from 'react'
import styled from 'styled-components'
import cart from './../assets/cart.svg'
import { observer } from 'mobx-react'
import useStore from '../store.js'
import { URL } from '../env.js'
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'
import favoriteIcon from './../assets/favorite-card.svg'
import { motion } from 'framer-motion'
import numeral from 'numeral'

const CardBody = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: #fff;
  width: 100%;
  max-width: 22rem;
  height: 100%;
`

const CardOverflow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 670px) {
    flex-direction: column;
  }
  gap: 0rem;
  width: 100%;
`

const CartImagesOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0rem;
  min-width: 45%;
  max-width: 59%;
  width: 100%;
`

const CardImage = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
  display: inline-block;
  width: 100%;
  height: 100%;

  @media (max-width: 670px) {
    height: 25rem;
  }
`

const CartMiniImagesOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  filter: opacity(0.5);
  cursor: pointer;
  height: 100%;
  width: 30%;
`

const CartImages = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
  width: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
`

const CardInfoBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.62rem;
  padding: 0.75rem 0.94rem 1.12rem 0.94rem;
  min-height: 23rem;
  @media (max-width: 670px) {
    min-height: 0rem;
  }
`

const CardInfoOverflow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.24rem;
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 5rem;

  @media (max-width: 670px) {
    padding-bottom: 0rem;
  }
`

const TitleOverflow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
`

const DescriptionOverflow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
`

const Title = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: -0.03em;
  color: #000;
`

const PriceOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.75rem;
`

const ParamOverflow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.75rem;
  margin-top: 0.5rem;
`

const Price = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #3b3b3b;
`

const ParamItemTitle = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #3b3b3b;
`

const VariantParamWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.4rem;
  flex-wrap: wrap;
`

const ColorParamWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.2rem;
`

const ParamColorOverflow = styled.div`
  padding: 2px; 
  border-radius: 100%;
  cursor: pointer;
`

const ParamVariantOverflow = styled.div`
  padding: 2px 8px; 
  border-radius: 10px;
  cursor: pointer;
`

const ParamVariantItem = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #3b3b3b;
`

const ParamColorCircle = styled.div`
   height: 1.2rem; 
   width: 1.2rem; 
   border-radius: 100%;
`

const Description = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: -0.03em;
  border: none;
  color: #000;
  user-select: text;
`

const ButtonBody = styled(motion.div)`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  height: 3rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: linear-gradient(90deg, #17b386 0%, #018dae 100%);
  width: 100%;
  position: absolute;
  bottom: 0px;
  margin-top: 1.5625rem;

  @media (max-width: 670px) {
    position: static;
  }
`

const ButtonOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1.06rem;
`

const Icon = styled.img`
  width: 1.9rem;
  height: 1.9rem;
`

const ButtonTextOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.12rem;
`

const BigText = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #000;
`

const TagsOverlowPadding = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1.38rem;
  padding: 0.38rem 0rem 0rem 0rem;
`

const TagsOverlow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.38rem;
` 

const TagBody = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0rem;
  border-radius: 0.38rem;
  padding: 0.31rem 0.56rem;
  background: ${props => props.color};
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 0.81rem;
  letter-spacing: -0.03em;
  color: #fff;
  cursor: pointer;
`

const Favorite = styled.img`
  width: 0.7rem;
  height: 0.7rem;
  margin-left: 0.6rem;
  transform: scale(1.7);
`

const Line = styled.div`
  width: 100%;
  height: 0.10rem;
  background: #ececec;
`

const CardBig = observer(({ style }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 670px)'
  })

  const store = useStore()

  const [card, setCard] = useState(false)
      , [index, setIndex] = useState(0)
      , [colorIndex, setColorIndex] = useState(0)
      , [variantIndex, setVariantIndex] = useState(0)

  useEffect(() => {
    if (store.card !== 0) {
      store.isLoad = true
      const timeId = setTimeout(() => {
        ;(async () => {
          try {
            const { data: card } = await axios.get(`${URL}/card?id=${store.card}`)
            setCard(card)
            store.isLoad = false
          } catch (e) {
            store.isLoad = true
          }   
        })()
      }, 500) 

      return () => clearTimeout(timeId) 
    }
  }, [store.card])

  if (!card) {
    return null
  }

  const image = `${URL}/assets/${card.images[index]}`

  const inCart = store.cart.filter(cart => cart.id+'-'+cart.colorIndex+'-'+cart.variantIndex === card.id+'-'+colorIndex+'-'+variantIndex).length

  return (
    <CardBody style={style}>
      <CardOverflow>
        <CartImagesOverflow>
          <CardImage src={image} />
          {
            card.images.length > 1
              ? (
                <CartMiniImagesOverflow>
                  {
                    card.images.map((image, _index) => (
                      _index !== index 
                        ? (
                          <CartImages 
                            key={_index} 
                            onClick={() => setIndex(_index)} 
                            style={{ height: `${(isMobile ? 25 : 100) / (card.images.length - 1)}${(isMobile ? 'rem' : '%')}` }} 
                            src={`${URL}/assets/${image}`} 
                          />
                        )
                        : null
                    ))
                  }
                </CartMiniImagesOverflow>
              )
              : (
                null
              )
          }
        </CartImagesOverflow>
        <CardInfoBody>
          <CardInfoOverflow>
            <TitleOverflow>
              <Title>
                {card.title || ''}
                {
                  store.favorite.find(_id => card.id === _id) 
                    ? (
                      <Favorite draggable={false} src={favoriteIcon} />
                    ) 
                    : null
                }
              </Title>
            </TitleOverflow>
            <DescriptionOverflow>
              <Description dangerouslySetInnerHTML={{ __html: card.description.replace(/\n/gi, '<br />') || '' }} />
            </DescriptionOverflow>
            <TagsOverlowPadding>
              <TagsOverlow>
                {
                  card.tags.slice(0, 3).map(
                    ({ tag, color }, key) => (
                      <TagBody 
                        name='tag'
                        whileTap={{ scale: 0.9 }}
                        onClick={e => {
                          if (e.target.getAttribute('name') === 'tag') {
                            e.stopPropagation()
                            e.preventDefault()
                            store.card = 0
                            store.q = `#${tag}`
                          }
                        }} 
                        color={color} 
                        key={key}
                      >
                        #{tag}
                      </TagBody>
                    )
                  )
                }
              </TagsOverlow>
            </TagsOverlowPadding>
            {
              card.colors.length > 1 
                ? (
                  <ParamOverflow>
                    <ParamItemTitle style={{ marginTop: '0.2rem' }}>Цвет:</ParamItemTitle>
                    <ColorParamWrapper>
                      {
                        card.colors.map((_color, key) => (
                          <ParamColorOverflow onClick={() => setColorIndex(key)} key={key} style={{ border: colorIndex === key ? '2px solid #3b3b3b' : '2px solid #3b3b3b00' }}>
                            <ParamColorCircle style={{ background: _color[0] }} />
                          </ParamColorOverflow>
                        ))
                      }
                    </ColorParamWrapper>         
                  </ParamOverflow>
                )
                : null
            }
            {
              card.variants.length > 1 
                ? (
                  <ParamOverflow style={{ marginTop: '0.2rem', flexWrap: 'wrap' }}>
                    <ParamItemTitle style={{ marginTop: '0.2rem' }}>Варианты:</ParamItemTitle>
                    <VariantParamWrapper>
                      {
                        card.variants.map((_variant, key) => (
                          <ParamVariantOverflow 
                            onClick={() => setVariantIndex(key)} 
                            key={key} 
                            style={{ 
                              border: variantIndex === key ? '2px solid #3b3b3b' : '2px solid #3b3b3b00', 
                              background: variantIndex === key ? '#fff' : '#ececec'
                            }}
                          >
                            <ParamVariantItem>{_variant[0]}</ParamVariantItem>
                          </ParamVariantOverflow>
                        ))
                      }
                    </VariantParamWrapper>         
                  </ParamOverflow>
                )
                : null
            }
            <Line style={{ marginTop: '0.2rem' }} />
            <PriceOverflow style={{ marginTop: '0.2rem' }}>
              <Price>Цена: {numeral((parseInt(card.price) + parseInt(card.colors[colorIndex][1] || 0) + parseInt(card.variants[variantIndex][1] || 0))).format('0,0').replace(/,/gi, ' ').trim() || ''} ₽</Price>
              {
                !card.delivery
                  ? (
                    <Price
                      style={{
                        textDecoration: 'underline',
                        textDecorationSkipInk: 'none'
                      }}
                    >без учета доставки</Price>
                  )
                  : (
                    null
                  )
              }
            </PriceOverflow>
            <ButtonBody whileTap={{ scale: 0.9 }} onClick={() => inCart <= 49 ? store.cart.push({ id: card.id, colorIndex, variantIndex }) : null}>
              <ButtonOverflow>
                <Icon draggable={false} src={cart} />
                <ButtonTextOverflow>
                  <BigText>Добавить в корзину{inCart > 0 ? ` × ${inCart}` : ''}</BigText>
                </ButtonTextOverflow>
              </ButtonOverflow>
            </ButtonBody>
          </CardInfoOverflow>
        </CardInfoBody>        
      </CardOverflow>
    </CardBody>
  )
})

export default CardBig