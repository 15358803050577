import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import useStore from '../store.js'
import { URL } from '../env.js'
import axios from 'axios'
import oformlenie from './../assets/oformlenie.gif'
import robot from './../assets/robot.png'
import { motion } from 'framer-motion'
import numeral from 'numeral'

const CartBody = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: #fff;
  width: 100%;
  max-width: 22rem;
  height: 100%;
`

const CartOverflow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 670px) {
    flex-direction: column;
  }
  gap: 0rem;
  width: 100%;
`

const CartInfoBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.94rem;
  padding: 1.12rem 0.94rem;
  width: 100%;
  box-sizing: border-box;
`

const CartImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
  width: 10rem;
  height: 10rem;
  min-width: 10rem;
  min-height: 10rem;
  border-radius: 0.94rem;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;

  @media (max-width: 670px) {
    width: 100%;
    height: 20rem;
    min-width: 100%;
    min-height: 20rem;
  }
`

const ItemOverflow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  gap: 0.62rem;

  @media (max-width: 670px) {
    flex-direction: column;
    align-items: flex-end;
  }
`

const CardInfoOverflow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.24rem;
  position: relative;
  height: 100%;
  width: auto;

  @media (max-width: 670px) {
    width: 100%;
  }
`

const TitleOverflow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
`

const Title = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: -0.03em;
  color: #000;
`

const DescriptionOverflow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
`

const Description = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: -0.03em;
  border: none;
  color: #000;

  @media (max-width: 370px) {
    font-size: 0.8rem;
  }
`

const PriceOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const Price = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #3b3b3b;
`

const OverflowBaseInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.94rem;
  width: 100%;

  @media (max-width: 670px) {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }
`

const ButtonsOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.06rem;
  width: 10rem;
  min-width: 10rem;
  
  @media (max-width: 670px) {
    flex-direction: row;
    width: 100%;
    max-width: 100%;
  }
`

const ButtonOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1.06rem;
`

const ButtonBody = styled(motion.div)`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  height: 3rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: linear-gradient(90deg, #17b386 0%, #018dae 100%);
  width: 100%;
  box-sizing: border-box;
`

const ButtonTextOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.12rem;
`

const BigText = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #000;

  @media (max-width: 370px) {
    font-size: 0.8rem;
  }
`

const Line = styled.div`
  width: 100%;
  height: 0.10rem;
  background: #ececec;
`

const OverflowNotofy = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 1.06rem;
  bottom: 0px;
  width: 100%;
`

const Oformlenie = styled.img`
  height: 18rem;
  border-radius: 0.94rem;
`

const OverflowNotifyInfo = styled.div`
  width: 15rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.24rem;
  position: relative;
`

const Robot = styled.img`
  height: 8rem;
  min-width: 50%;

  @media (max-width: 670px) {
    height: 5rem;
  }
`

const ParamOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.75rem;
  margin-top: 0.5rem;
`

const ParamItemTitle = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #3b3b3b;
`

const ParamVariantItem = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #3b3b3b;
`

const ParamColorCircle = styled.div`
   height: 1.2rem; 
   width: 1.2rem; 
   border-radius: 100%;
`

const Item = observer(({ images, title, price, colors, variants, count, colorIndex, variantIndex, description, id, delivery }) => {
  const store = useStore()

  return (
    <ItemOverflow>
      <OverflowBaseInfo>
        <CartImage src={`${URL}/assets/${images[0]}`} />
        <CardInfoOverflow>
          <TitleOverflow>
            <Title>{title}</Title>
          </TitleOverflow>
          <DescriptionOverflow>
            <Description>{(d => d.length > 30 ? d.slice(0, 30)+' ...' : d)(description)}</Description>
          </DescriptionOverflow>
          {
            colors.length > 1 
              ? (
                <ParamOverflow>
                  <ParamItemTitle>Цвет:</ParamItemTitle>
                  <ParamColorCircle style={{ background: colors[colorIndex][0] }} />
                </ParamOverflow>
              )
              : null
          }
          {
            variants.length > 1 
              ? (
                <ParamOverflow style={{ marginTop: '0.2rem', flexWrap: 'wrap' }}>
                  <ParamItemTitle>Вариант:</ParamItemTitle>
                  <ParamVariantItem>{(d => d.length > 30 ? d.slice(0, 30)+' ...' : d)(variants[variantIndex][0])}</ParamVariantItem>      
                </ParamOverflow>
              )
              : null
          }
          <Line style={{ marginTop: '0.2rem' }} />
          <PriceOverflow style={{ marginTop: '0.2rem' }}>
            <Price>Цена: {numeral(parseInt(price) + parseInt(colors[colorIndex][1]) + parseInt(variants[variantIndex][1])).format('0,0').replace(/,/gi, ' ').trim()} ₽{count > 1 ? ` × ${count}` : ''}</Price>
            {
              !delivery 
                ? (
                  <Price
                    style={{
                      textDecoration: 'underline',
                      textDecorationSkipInk: 'none'
                    }}
                  >без учета доставки</Price>
                )
                : (
                  null
                )
            }
          </PriceOverflow>
        </CardInfoOverflow>
      </OverflowBaseInfo>
      <ButtonsOverflow>
        <ButtonBody
          whileTap={{ scale: 0.9 }}
          onClick={() => count <= 49 ? store.cart.push({ id, colorIndex, variantIndex }) : null}
        >
          <ButtonOverflow>
            <ButtonTextOverflow>
              <BigText>Добавить</BigText>
            </ButtonTextOverflow>
          </ButtonOverflow>
        </ButtonBody>
        <ButtonBody
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            const index = store.cart.findIndex(cart => cart.id === id && cart.colorIndex === colorIndex && cart.variantIndex === variantIndex)
            store.cart = store.cart.filter((_, _index) => _index !== index)
          }}
          style={{
            background: '#000',
          }}
        >
          <ButtonOverflow>
            <ButtonTextOverflow>
              <BigText
                style={{
                  color: '#fff'
                }}
              >
                Убрать
              </BigText>
            </ButtonTextOverflow>
          </ButtonOverflow>
        </ButtonBody>
      </ButtonsOverflow>
    </ItemOverflow>
  )
})

const Cart = observer(({ style }) => {
  const store = useStore()

  const cartObj = store.cart.filter(cartObj => typeof(cartObj) === 'object' && (cartObj) instanceof Object).reduce((ctx, elem) => {
    const jsonElem = JSON.stringify(elem)
    
    if (ctx[jsonElem]) {
      ctx[jsonElem] += 1
    } else {
      ctx[jsonElem] = 1
    }

    return ctx
  }, {})

  const cart = Object.keys(cartObj).map(
    jsonElem => {
      const elem = JSON.parse(jsonElem)
      return ({ 
        id: parseInt(elem.id), 
        colorIndex: elem.colorIndex,
        variantIndex: elem.variantIndex,
        count: cartObj[jsonElem] 
      })
    }
  )

  const [cards, setCards] = useState([])

  useEffect(() => {
    if (cart.length !== 0) {
      store.isLoad = true
      const timeId = setTimeout(() => {
        ;(async () => {
          try {
            const { data: cards } = await axios.get(`${URL}/card?ids=${cart.map(({ id }) => id).join(',')}`)
            setCards(cards)
            store.isLoad = false
          } catch (e) {
            store.isLoad = true
          }
        })()
      }, 500) 

      return () => clearTimeout(timeId) 
    }
  }, [cart.flat().join('')])

  const items = cart.map(cart => ({ ...cards.find(card => card.id === cart.id), ...cart }))

  useEffect(() => {
    if (cart.length === 0) {
      store.isLoad = true
      setTimeout(() => { 
        store.isLoad = false
      }, 500)
    }
  }, [cart])

  if (cart.length === 0) {
    store.navigation = 0
    return null
  }

  if (cards.length === 0) {
    return null
  }

  const handleCart = async () => {
    try {
      const { data: ok } = await axios.get(`${URL}/status`)
      if (ok) {
        window.location.href = `${URL}/bot?data=${btoa(JSON.stringify({ cart, id: store.id }))}`
      }
    } catch (e) {}
  }

  return (
    <CartBody style={style}>
      <CartOverflow>
        <CartInfoBody>
          <>
            {
              items.sort((a, b) => (a.id + a.variantIndex + a.colorIndex) - (b.id + b.variantIndex + b.colorIndex)).map((item, key) => <Item key={key} {...item} />)
            }
          </>
          <Line />
          <PriceOverflow style={{ marginTop: '0.2rem' }}>
            <Price>Итоговая сумма: {numeral(items.reduce((ctx, item) => ctx + (item.count * (parseInt(item.price) + parseInt(item.colors[item.colorIndex][1]) + parseInt(item.variants[item.variantIndex][1]))), 0)).format('0,0').replace(/,/gi, ' ').trim()} ₽</Price>
          </PriceOverflow>
          <Line />
          <OverflowNotofy>
            <OverflowNotifyInfo>
              <Robot draggable={false} src={robot} />
              <Description style={{ textAlign: 'center' }}>Ваш заказ готов, осталось только формить его через нашего бота в телеграм, это займет не больше секунды!</Description>
              <ButtonsOverflow>
                <ButtonBody
                  style={{
                    marginTop: '1.5625rem',
                    position: 'absolute',
                    bottom: '0px'
                  }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => handleCart()}
                >
                  <ButtonOverflow>
                    <ButtonTextOverflow>
                      <BigText>Оформить заказ</BigText>
                    </ButtonTextOverflow>
                  </ButtonOverflow>
                </ButtonBody>
              </ButtonsOverflow>
            </OverflowNotifyInfo>
            <Oformlenie draggable={false} src={oformlenie} />
          </OverflowNotofy>
        </CartInfoBody>
      </CartOverflow>
    </CartBody>
  )
})

export default Cart