import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import mudozulina from './../assets/mudozulina.png'
import logo from './../assets/logo.svg'
import { observer } from 'mobx-react-lite'
import useStore from '../store'

const Overflow = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.62rem;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
`

const LogoOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1.12rem;
`

const LogoCircle = styled.div`
  border-radius: 0.94rem;
  width: 7.5rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: linear-gradient(225deg, #018dae 0%, #17b386 100%);
`

const LogoText = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 1.62rem;
  line-height: 104%;
  letter-spacing: -0.03em;
  color: #000;
  position: relative;
`

const Mudozulina = styled.div`
  width: 8rem;
  height: 1.69rem;
  background-image: url(${mudozulina});
  position: absolute;
  top: 1.67rem;
  left: -0.3rem;
`

const T = styled.div`
  position: relative;
  z-index: 999;
`

const LogoIcon = styled.div`
  background-image: url(${logo});
  background-size: cover;
  width: 5.375rem;
  height: 5.875rem;
`

const Logo = observer(() => {
  const store = useStore()

  const [state, setState] = useState(false)
      , [initAnimation, setInitAnimation] = useState(false)

  useEffect(() => {
    if (state) {
      const timeId = setTimeout(() => {
        setState(false)
        setInitAnimation(true)
      }, 1000)
  
      return () => clearTimeout(timeId)
    }
  }, [state])

  return (
    <Overflow
      onTapStart={() => setState(true)}
      onClick={() => {
        setTimeout(() => {
          store.card = 0
          store.q = 0
          store.navigation = 0
          window.history.pushState('', null, `?qd=WzAsMCxbXV0O`)  
          window.location.reload()
        }, 2000)
      }}
    >
      <LogoOverflow>
        <LogoCircle>
          <motion.div
            style={{ outline: 'none' }} 
            transition={{
              duration: initAnimation ? state ? 1 : 0.5 : 1,
              ease: 'linear'
            }} 
            initial={{ rotate: -360 * 2 }}
            animate={state ? { rotate: 360 * 2 } : { rotate: 0 }}
          >
            <LogoIcon />
          </motion.div>
        </LogoCircle>
        <LogoText>
          <T>ЛАВКА</T>
          <T style={{ color: state ? '#fff' : '#000' }}>НИЖНИХ</T>
          <T>ИНТЕРНЕТОВ</T>
          {state ? <Mudozulina /> : null}
        </LogoText>
      </LogoOverflow>
    </Overflow>
  )
})

export default Logo