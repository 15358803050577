import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import useStore from '../store'
import search from './../assets/search.svg'
import menu from './../assets/menu.svg'
import cart from './../assets/cart.svg'
import books from './../assets/books.png'
import pepels from './../assets/pepels.png'
import footbal from './../assets/footbal.png'
import favorites from './../assets/favorites.svg'
import favoritesBlack from './../assets/favorites-black.svg'
import home from './../assets/home.svg'
import trash from './../assets/trash.svg'
import fires from './../assets/fires.svg'
import pins from './../assets/pins.svg'
import stikers from './../assets/stikers.svg'
import vidoes from './../assets/videos.svg'
import axios from 'axios'
import { URL } from '../env'
import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion'

const Body = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
`

const NavigationOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1.25rem;
`

const ButtonOverflow = styled(motion.div)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  padding: 1.19rem 1.25rem;
  height: 4.12rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: #fff;
  box-sizing: border-box;
  width: 100%;
  min-width: 6.5rem;
  max-width: 8rem;
`

const Icon = styled.img`
  width: 1.9rem;
  height: 1.9rem;
`

const OverflowLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
  padding: 0rem 0rem 0rem 1.25rem;
`

const OverflowMenu = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.94rem;
`

const OverflowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
`

const IconButton = styled.img`
  gap: 0.62rem;
  height: 2.25rem;
`

const TextButton = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  color: #3b3b3b;
`

const InputOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  padding: 1rem 1.25rem;
  width: 100%;
  height: 4.12rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: #fff;
  box-sizing: border-box;
  position: relative;
`

const Input = styled.input`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: -0.03em;
  border: none;
  width: 100%;
  color: #000;

  &::placeholder {
    color: #b9b9b9;
  }
`

const Text = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: -0.03em;
  color: #000;
`

const TagsOverlowPadding = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1.38rem;
  padding: 0.38rem 0rem 0rem 0rem;
`

const TagsOverlow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.38rem;
` 

const TagBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0rem;
  border-radius: 0.38rem;
  padding: 0.31rem 0.56rem;
  background: ${props => props.color};
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 0.81rem;
  letter-spacing: -0.03em;
  color: #fff;
  cursor: pointer;
`

const Line = styled.div`
  width: calc(100% - 1.25rem);
  height: 0.10rem;
  background: #ececec;
`

const NavigationMobile = observer(() => {
  const isMobile = useMediaQuery({
    query: '(max-width: 450px)'
  })

  const store = useStore()

  const [tags, setTags] = useState([])

  useEffect(() => {
    const timeId = setTimeout(() => {
      ;(async () => {
        try {
          const { data } = await axios.get(`${URL}/tags`)
          setTags(data)
        } catch (e) {}
      })()
    }, 500) 

    return () => clearTimeout(timeId) 
  }, [])

  return (
    <Body>
      <NavigationOverflow>
        {
          store.card === 0 || !isMobile
            ? (
              <>
                <ButtonOverflow 
                  whileTap={{ scale: 0.9 }}
                  onClick={
                    () => {
                      store.navigation === 1 
                        ? store.navigation = 0 
                        : store.navigation = 1

                      store.card = 0
                    }
                  } 
                  style={store.navigation === 1 ? { filter: 'invert(1)' } : {}}>
                  <Icon draggable={false} src={search} />
                </ButtonOverflow>
                <ButtonOverflow 
                  whileTap={{ scale: 0.9 }}
                  onClick={
                    () => {
                      store.navigation === 2 
                        ? store.navigation = 0 
                        : store.navigation = 2
                      
                      store.card = 0
                    }
                  } 
                  style={store.navigation === 2 ? { filter: 'invert(1)' } : {}}
                >
                  <Icon draggable={false} src={menu} />
                </ButtonOverflow>
              </>
            )
            : (
              <ButtonOverflow 
                whileTap={{ scale: 0.9 }}
                onClick={
                  () => {
                    store.navigation = 0
                    store.card = 0
                    store.q = ''
                    store.isLoad = true
                    setTimeout(() => {
                      store.isLoad = false
                    }, 500)
                  }
                } 
                style={store.navigation === 2 ? { filter: 'invert(1)' } : {}}
              >
                <Icon draggable={false} src={home} />
              </ButtonOverflow>
            )
        }
        <ButtonOverflow 
          whileTap={{ scale: 0.9 }}
          onClick={
            () => {
              store.navigation === 3 
                ? store.navigation = 0 
                : store.navigation = 3

              store.card = 0
            }
          } 
          style={
            store.navigation === 3 
              ? { 
                filter: 'invert(1)' 
              } : {
                
              }
          }
        >
          <Icon draggable={false} src={cart} />
          {
            store.cart.length !== 0 
              ? (
                <Text>{store.cart.length}</Text>
              )
              : (
                null
              )
          }
        </ButtonOverflow>
        {
          store.cart.find(id => id === store.card) 
            ? isMobile 
                ? (
                  <ButtonOverflow 
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                      const index = store.cart.findIndex(id => id === store.card)
                      
                      if (index !== -1) {
                        store.cart = store.cart.filter((_, _index) => index !== _index)
                      } else {
                        store.cart = [
                          ...store.cart.filter(id => store.card !== id), 
                          store.card
                        ]
                      }
                    }}
                  >
                    <Icon draggable={false} src={trash} />
                  </ButtonOverflow>
                )
                : (
                  <>
                    <ButtonOverflow 
                      whileTap={{ scale: 0.9 }}
                      onClick={() => {
                        const index = store.cart.findIndex(id => id === store.card)
                        
                        if (index !== -1) {
                          store.cart = store.cart.filter((_, _index) => index !== _index)
                        } else {
                          store.cart = [
                            ...store.cart.filter(id => store.card !== id), 
                            store.card
                          ]
                        }
                      }}
                    >
                      <Icon draggable={false} src={trash} />
                    </ButtonOverflow>
                    <ButtonOverflow 
                      whileTap={{ scale: 0.9 }}
                      onClick={() => {
                        const index = store.favorite.findIndex(id => id === store.card)
                        
                        if (index !== -1) {
                          store.favorite = store.favorite.filter(id => store.card !== id)
                        } else {
                          store.favorite = [
                            ...store.favorite.filter(id => store.card !== id), 
                            store.card
                          ]
                        }
                      }}
                      style={!!store.favorite.find(id => store.card === id) ? { filter: 'invert(1)' } : {}}
                    >
                      <Icon draggable={false} src={favoritesBlack} />
                    </ButtonOverflow>
                  </>
                )
            : store.card 
                ? (
                  <ButtonOverflow 
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                      const index = store.favorite.findIndex(id => id === store.card)
                      
                      if (index !== -1) {
                        store.favorite = store.favorite.filter(id => store.card !== id)
                      } else {
                        store.favorite = [
                          ...store.favorite.filter(id => store.card !== id), 
                          store.card
                        ]
                      }
                    }}
                    style={!!store.favorite.find(id => store.card === id) ? { filter: 'invert(1)' } : {}}
                  >
                    <Icon draggable={false} src={favoritesBlack} />
                  </ButtonOverflow>
                )
                : (
                  null
                )
        }
      </NavigationOverflow>
      {
        store.navigation === 1 
          ? (
            <InputOverflow>
              <Input 
                placeholder='Поиск' 
                value={store.q} 
                onFocus={
                  ({ target: { value } }) => {
                    store.q = value.match(/(#|→ )/) ? '' : value
                    store.card = 0
                    store.navigation = 0
                  }
                }
                onChange={({ target: { value } }) => {
                  store.q = value.match(/(#|→ )/) ? '' : value
                  store.card = 0
                }}  
              />
            </InputOverflow>
          )
          : (
            null
          )
      }
      {
        store.navigation === 2 
          ? (
            <OverflowLeft>
              <OverflowMenu>
                <OverflowButton 
                  onClick={
                    () => {
                      store.q = ''
                      store.card = 0
                      store.navigation = store.navigation === 4 ? 0 : 4

                      store.isLoad = true
                      setTimeout(() => {
                        store.isLoad = false
                      }, 500)
                    }
                  }>
                  <IconButton draggable={false} src={favorites} />
                  <TextButton
                    style={
                      store.navigation === 4
                        ? {
                          textDecoration: 'underline',
                          textDecorationSkipInk: 'none'
                        } 
                        : {}
                    }
                  >Избранные товары</TextButton>
                </OverflowButton>
                <OverflowButton 
                  onClick={
                    () => {
                      store.q === '→ футболки' 
                        ? store.q = '' 
                        : store.q = '→ футболки'
                      store.navigation = 0
                      store.card = 0
                    }
                  }
                >
                  <IconButton draggable={false} src={footbal} />
                  <TextButton
                    style={
                      store.q.toLowerCase() === '→ футболки' 
                        ? {
                          textDecoration: 'underline',
                          textDecorationSkipInk: 'none'
                        } 
                        : {}
                    }
                  >Футболки</TextButton>
                </OverflowButton>
                <OverflowButton 
                  onClick={
                    () => {
                      store.q === '→ пепельницы' 
                        ? store.q = '' 
                        : store.q = '→ пепельницы'
                      store.navigation = 0
                      store.card = 0
                    }
                  }
                >
                  <IconButton draggable={false} src={pepels} />
                  <TextButton
                    style={
                      store.q.toLowerCase() === '→ пепельницы' 
                        ? {
                          textDecoration: 'underline',
                          textDecorationSkipInk: 'none'
                        } 
                        : {}
                    }
                  >Пепельницы</TextButton>
                </OverflowButton>
                <OverflowButton 
                  onClick={
                    () => {
                      store.q === '→ зажигалки' 
                        ? store.q = '' 
                        : store.q = '→ зажигалки'
                      store.navigation = 0
                      store.card = 0
                    }
                  }
                >
                  <IconButton draggable={false} src={fires} />
                  <TextButton
                    style={
                      store.q.toLowerCase() === '→ зажигалки' 
                        ? {
                          textDecoration: 'underline',
                          textDecorationSkipInk: 'none'
                        } 
                        : {}
                    }
                  >Зажигалки</TextButton>
                </OverflowButton>
                <OverflowButton 
                  onClick={
                    () => {
                      store.q === '→ стикеры' 
                        ? store.q = '' 
                        : store.q = '→ стикеры'
                      store.navigation = 0
                      store.card = 0
                    }
                  }
                >
                  <IconButton draggable={false} src={stikers} />
                  <TextButton
                    style={
                      store.q.toLowerCase() === '→ стикеры' 
                        ? {
                          textDecoration: 'underline',
                          textDecorationSkipInk: 'none'
                        } 
                        : {}
                    }
                  >Стикеры</TextButton>
                </OverflowButton>
                <OverflowButton 
                  onClick={
                    () => {
                      store.q === '→ значки' 
                        ? store.q = '' 
                        : store.q = '→ значки'
                      store.navigation = 0
                      store.card = 0
                    }
                  }
                >
                  <IconButton draggable={false} src={pins} />
                  <TextButton
                    style={
                      store.q.toLowerCase() === '→ значки' 
                        ? {
                          textDecoration: 'underline',
                          textDecorationSkipInk: 'none'
                        } 
                        : {}
                    }
                  >Значки</TextButton>
                </OverflowButton>
                <OverflowButton 
                  onClick={
                    () => {
                      store.q === '→ книги' 
                        ? store.q = '' 
                        : store.q = '→ книги'
                      store.navigation = 0
                      store.card = 0
                    }
                  }
                >
                  <IconButton draggable={false} src={books} />
                  <TextButton
                    style={
                      store.q.toLowerCase() === '→ книги' 
                        ? {
                          textDecoration: 'underline',
                          textDecorationSkipInk: 'none'
                        } 
                        : {}
                    }
                  >Книги</TextButton>
                </OverflowButton>
                <OverflowButton 
                  onClick={
                    () => {
                      store.q === '→ видео' 
                        ? store.q = '' 
                        : store.q = '→ видео'
                      store.navigation = 0
                      store.card = 0
                    }
                  }
                >
                  <IconButton draggable={false} src={vidoes} />
                  <TextButton
                    style={
                      store.q.toLowerCase() === '→ видео' 
                        ? {
                          textDecoration: 'underline',
                          textDecorationSkipInk: 'none'
                        } 
                        : {}
                    }
                  >Видео</TextButton>
                </OverflowButton>
                <TagsOverlowPadding>
                  <TagsOverlow>
                    {
                      tags.map(
                        ({ tag, color }, key) => (
                          <TagBody 
                            name='tag' 
                            onClick={e => {
                              if (e.target.getAttribute('name') === 'tag') {
                                e.stopPropagation()
                                e.preventDefault()
                                store.card = 0
                                store.q = `#${tag}`
                              }
                            }} 
                            color={color} 
                            key={key}
                          >
                            #{tag}
                          </TagBody>
                        )
                      )
                    }
                  </TagsOverlow>
                </TagsOverlowPadding>
                <Line />
              </OverflowMenu>
            </OverflowLeft>
          )
          : (
            null
          )
      }
    </Body>
  )
})

export default NavigationMobile