import styled from 'styled-components'
import oninet from './../assets/oninet.svg'
import { motion } from 'framer-motion'

const Body = styled(motion.div)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  width: 100%;
  height: 5rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: linear-gradient(90deg, #17b386 0%, #018dae 100%);
`

const Overflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1.06rem;
`

const Icon = styled.div`
  background-image: url(${oninet});
  background-size: cover;
  width: 1.75rem;
  height: 2rem;
`

const TextOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.12rem;
`

const BigText = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #000;

  @media (max-width: 370px) {
    font-size: 0.8rem;
  }
`

const Line = styled.div`
  width: 15.88rem;
  height: 0.10rem;
  background: #000;
`

const Text = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #000;

  @media (max-width: 370px) {
    font-size: 0.8rem;
  }
`

const OniAds = () => {

  return (
    <Body whileTap={{ scale: 0.9 }} onClick={() => window.location.href = 'https://t.me/odinoky_nizny_internet'}>
      <Overflow>
        <Icon />
        <TextOverflow>
          <BigText>ОДИНОКИЙ НИЖНИЙ ИНТЕРНЕТ</BigText>
          <Line />
          <Text>Лучшие люди знакомятся здесь</Text>
        </TextOverflow>
      </Overflow>
    </Body>
  )
}

export default OniAds