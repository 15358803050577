import { useEffect, useState } from 'react'
import styled from 'styled-components'
import search from './../assets/search.svg'
import { observer } from 'mobx-react'
import useStore from './../store'
import books from './../assets/books.png'
import pepels from './../assets/pepels.png'
import footbal from './../assets/footbal.png'
import favorites from './../assets/favorites.svg'
import fires from './../assets/fires.svg'
import pins from './../assets/pins.svg'
import stikers from './../assets/stikers.svg'
import vidoes from './../assets/videos.svg'
import axios from 'axios'
import { URL } from '../env'
import { motion } from 'framer-motion'

const Body = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.25rem;
`

const InputOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  padding: 1rem 1.25rem;
  width: 18.44rem;
  height: 4.12rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: #fff;
  box-sizing: border-box;
  position: relative;
`

const Input = styled.input`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: -0.03em;
  border: none;
  color: #000;

  &::placeholder {
    color: #b9b9b9;
  }
`

const OverflowLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
  padding: 0rem 0rem 0rem 1.25rem;
`

const OverflowMenu = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.94rem;
  width: calc(18.44rem - 1.25rem);
`

const OverflowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.62rem;
  cursor: pointer;
`

const IconButton = styled.img`
  gap: 0.62rem;
  height: 2.25rem;
`

const TextButton = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  color: #3b3b3b;
`

const Icon = styled.img`
  width: 1.9rem;
  height: 1.9rem;
  position: absolute; 
  right: 1.25rem;
`

const TagsOverlowPadding = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1.38rem;
  padding: 0.38rem 0rem 0rem 0rem;
`

const TagsOverlow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.38rem;
` 

const TagBody = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0rem;
  border-radius: 0.38rem;
  padding: 0.31rem 0.56rem;
  background: ${props => props.color};
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 0.81rem;
  letter-spacing: -0.03em;
  color: #fff;
  cursor: pointer;
`

const Navigation = observer(() => {
  const store = useStore()

  const [tags, setTags] = useState([])

  useEffect(() => {
    const timeId = setTimeout(() => {
      ;(async () => {
        try {
          const { data } = await axios.get(`${URL}/tags`)
          setTags(data)
        } catch (e) {}
      })()
    }, 500) 

    return () => clearTimeout(timeId) 
  }, [])

  return (
    <Body>
      <InputOverflow>
        <Input 
          placeholder='Поиск' 
          value={store.q} 
          onFocus={
            ({ target: { value } }) => {
              store.q = value.match(/(#|→ )/) ? '' : value
              store.card = 0
              store.navigation = 0
            }
          }
          onChange={
            ({ target: { value } }) => {
              store.q = value.match(/(#|→ )/) ? '' : value
              store.card = 0
              store.navigation = 0
            }
          }  
        />
        {
          store.q.length < 21
            ? (
              <Icon draggable={false} src={search} />
            )
            : (
              null
            )
        }
      </InputOverflow>
      <OverflowLeft>
        <OverflowMenu>
          <OverflowButton 
            onClick={
              () => {
                store.q = ''
                store.card = 0
                store.navigation = store.navigation === 4 ? 0 : 4

                store.isLoad = true
                setTimeout(() => {
                  store.isLoad = false
                }, 500)
              }
            }>
            <IconButton draggable={false} src={favorites} />
            <TextButton
              style={
                store.navigation === 4
                  ? {
                    textDecoration: 'underline',
                    textDecorationSkipInk: 'none'
                  } 
                  : {}
              }
            >Избранные товары</TextButton>
          </OverflowButton>
          <OverflowButton 
            onClick={() => {
              store.q === '→ футболки' 
                ? store.q = '' 
                : store.q = '→ футболки'
              store.card = 0
              store.navigation = 0
            }}>
            <IconButton draggable={false} src={footbal} />
            <TextButton
              style={
                store.q.toLowerCase() === '→ футболки' 
                  ? {
                    textDecoration: 'underline',
                    textDecorationSkipInk: 'none'
                  } 
                  : {}
              }
            >Футболки</TextButton>
          </OverflowButton>
          <OverflowButton 
            onClick={
              () => {
                store.q === '→ пепельницы' 
                  ? store.q = '' 
                  : store.q = '→ пепельницы'
                store.card = 0
                store.navigation = 0
              }
            }>
            <IconButton draggable={false} src={pepels} />
            <TextButton
              style={
                store.q.toLowerCase() === '→ пепельницы' 
                  ? {
                    textDecoration: 'underline',
                    textDecorationSkipInk: 'none'
                  } 
                  : {}
              }
            >Пепельницы</TextButton>
          </OverflowButton>
          <OverflowButton 
            onClick={
              () => {
                store.q === '→ зажигалки' 
                  ? store.q = '' 
                  : store.q = '→ зажигалки'
                store.card = 0
                store.navigation = 0
              }
            }
          >
            <IconButton draggable={false} src={fires} />
            <TextButton
              style={
                store.q.toLowerCase() === '→ зажигалки' 
                  ? {
                    textDecoration: 'underline',
                    textDecorationSkipInk: 'none'
                  } 
                  : {}
              }
            >Зажигалки</TextButton>
          </OverflowButton>
          <OverflowButton 
            onClick={
              () => {
                store.q === '→ стикеры' 
                  ? store.q = '' 
                  : store.q = '→ стикеры'
                store.card = 0
                store.navigation = 0
              }
            }
          >
            <IconButton draggable={false} src={stikers} />
            <TextButton
              style={
                store.q.toLowerCase() === '→ стикеры' 
                  ? {
                    textDecoration: 'underline',
                    textDecorationSkipInk: 'none'
                  } 
                  : {}
              }
            >Стикеры</TextButton>
          </OverflowButton>
          <OverflowButton 
            onClick={
              () => {
                store.q === '→ значки' 
                  ? store.q = '' 
                  : store.q = '→ значки'
                store.card = 0
                store.navigation = 0
              }
            }
          >
            <IconButton draggable={false} src={pins} />
            <TextButton
              style={
                store.q.toLowerCase() === '→ значки' 
                  ? {
                    textDecoration: 'underline',
                    textDecorationSkipInk: 'none'
                  } 
                  : {}
              }
            >Значки</TextButton>
          </OverflowButton>
          <OverflowButton 
            onClick={
              () => {
                store.q === '→ книги' 
                  ? store.q = '' 
                  : store.q = '→ книги'
                store.card = 0
                store.navigation = 0
              }
            }>
            <IconButton draggable={false} src={books} />
            <TextButton
              style={
                store.q.toLowerCase() === '→ книги' 
                  ? {
                    textDecoration: 'underline',
                    textDecorationSkipInk: 'none'
                  } 
                  : {}
              }
            >Книги</TextButton>
          </OverflowButton>
          <OverflowButton 
            onClick={
              () => {
                store.q === '→ видео' 
                  ? store.q = '' 
                  : store.q = '→ видео'
                store.card = 0
                store.navigation = 0
              }
            }>
            <IconButton draggable={false} src={vidoes} />
            <TextButton
              style={
                store.q.toLowerCase() === '→ видео' 
                  ? {
                    textDecoration: 'underline',
                    textDecorationSkipInk: 'none'
                  } 
                  : {}
              }
            >Видео</TextButton>
          </OverflowButton>
          <TagsOverlowPadding>
            <TagsOverlow>
              {
                tags.map(
                  ({ tag, color }, key) => (
                    <TagBody 
                      name='tag' 
                      whileTap={{ scale: 0.9 }}
                      onClick={e => {
                        if (e.target.getAttribute('name') === 'tag') {
                          e.stopPropagation()
                          e.preventDefault()
                          store.navigation = 0
                          store.card = 0
                          store.q = `#${tag}`
                        }
                      }} 
                      color={color} 
                      key={key}
                    >
                      #{tag}
                    </TagBody>
                  )
                )
              }
            </TagsOverlow>
          </TagsOverlowPadding>
        </OverflowMenu>
      </OverflowLeft>
    </Body>
  )
})

export default Navigation