import styled from 'styled-components'
import { motion } from "framer-motion"
import logo from './../assets/logo.svg'
import { observer } from 'mobx-react'

const LogoCircle = styled.div`
  position: fixed;
  z-index: 999999999999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: linear-gradient(225deg, #018dae 0%, #17b386 100%);
`

const LogoIcon = styled.div`
  background-image: url(${logo});
  background-size: cover;
  width: 5.375rem;
  height: 5.875rem;
`

const Loader = observer(() => {
  return (
    <LogoCircle>
      <motion.div
        style={{ outline: 'none' }} 
        transition={{
          duration: 5,
          ease: 'linear'
        }} 
        initial={{ 
          rotate: 0,
          scale: 1
        }}
        animate={{ rotate: 360 * 10 }}
        whileTap={{ 
          scale: 0.8, 
          transition: { duration: 0.2 } 
        }}
      >
        <LogoIcon />
      </motion.div>
    </LogoCircle>
  )
})

export default Loader