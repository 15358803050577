import styled from 'styled-components'
import telegram from './../assets/telegram.svg'
import { motion } from 'framer-motion'

const Body = styled(motion.div)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  width: 100%;
  height: 5rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: linear-gradient(90deg, #2aabee 0%, #229ed9 100%);
`

const Overflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1.06rem;
`

const Icon = styled.div`
  background-image: url(${telegram});
  background-size: cover;
  width: 2.1875rem;
  height: 1.8125rem;
`

const TextOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.12rem;
`

const BigText = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #fff;
`

const Mc = () => {
  return (
    <Body whileTap={{ scale: 0.9 }} onClick={() => window.location.href = 'https://t.me/mckrapiva1'}>
      <Overflow>
        <Icon />
        <TextOverflow>
          <BigText>МЦ Крапива здесь</BigText>
        </TextOverflow>
      </Overflow>
    </Body>
  )
}

export default Mc