import React from 'react'
import ReactDOM from 'react-dom/client'
import styled from 'styled-components'
import './index.css'

import Main from './Main'

const Overflow = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 10px;
  box-sizing: border-box;
`

const Body = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: auto;

  @media (max-width: 670px) {
    width: 100%;
  }
`

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Overflow>
      <Body>
        <Main />
      </Body>
    </Overflow>
  </React.StrictMode>
)