import { useEffect } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import Logo from './Components/logo'
import Navigation from './Components/navigation'
import NavigationMobile from './Components/navigation-mobile'
import Cards from './Components/cards'
import Right from './Components/right'
import { observer } from 'mobx-react'
import useStore from './store'
import axios from 'axios'
import { IS_DEV, URL } from './env.js'
import Loader from './Components/loader.js'

const Overflow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1.56rem;
  max-width: 1280px;
`

const Main = observer(() => {
  const isMain = useMediaQuery({
    query: '(min-width: 1025px)'
  })
  
  useEffect(() => {
    const timeId = setTimeout(() => {
      if (!IS_DEV && window.location.protocol === 'http:' && !window.location.origin.match(/localhost/)) {
        window.location.href = window.location.href.replace(/https:/)
      }
    }, 1000)
    
    return () => clearTimeout(timeId)
  }, [])

  const store = useStore()

  useEffect(() => {
    const intervalId = setInterval(() => {
      ;(async () => {
        try {
          if (store.cart.length > 0) {
            const { data: ok } = await axios.get(`${URL}/startbot?id=${store.id}`)
            if (ok) {
              store.cart = []
              store.navigation = 0
              store.card = 0
              store.q = ''
              await axios.get(`${URL}/cartclean?id=${store.id}`)
              store.id = Math.random().toString().replace('.', '')
              window.location.href = window.location.host === 'localhost:3000'
                                      ? window.location.origin 
                                      : URL
            }
          }
        } catch (e) {
          console.log(e)
        }
      })()
    }, 2000)

    return () => clearInterval(intervalId)
  }, [store])

  const q = store.q.replace('#', '').toLowerCase()

  useEffect(() => {
    if (
      store.card === 0 && 
      (store.q.length > 3 || store.q.length === 0) && 
      store.navigation !== 3 && 
      store.navigation !== 4
    ) {
      if (store.q.match(/(#|→ )/)) {
        store.isLoad = true
      }

      const timeId = setTimeout(() => {
        ;(async () => {
          try {
            const q = store.q.replace('#', '').replace('→ ', '').toLowerCase()
            store.isLoad = true
            const { data: cards } = await axios.get(`${URL}/search?q=${encodeURIComponent(q)}`)
            store.cards = cards
            store.isLoad = false
          } catch (e) {
            store.isLoad = true
          }  
        })()
      }, 1500) 
  
      return () => clearTimeout(timeId)
    }
  }, [q, store.card])

  return (
    <>
      <Logo />
      <Overflow
        style={{
          flexDirection: isMain ? 'row' : 'column'
        }}
      >
        {
          isMain 
            ? (
              <Navigation />
            )
            : <NavigationMobile />
        }
        <Cards />
        {
          isMain 
            ? (
              <Right />
            )
            : null
        }
        {
          store.isLoad 
            ? (
              <Loader />
            )
            : null
        }
      </Overflow>
    </>
  )
})

export default Main