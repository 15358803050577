import { createContext, useContext } from 'react'
import { observable, autorun, toJS } from 'mobx'
import queryString from 'query-string'

let defaultStore = {
  q: '',
  cards: [],
  cart: [],
  favorite: [],
  navigation: 0,
  card: 0,
  isLoad: true,
  id: Math.random().toString().replace('.', '')
}

try {
  defaultStore = {
    ...defaultStore,
    ...JSON.parse(localStorage.store)
  }
} catch (e) {}

try {
  const parsed = queryString.parse(window.location.search)

  const [card, navigation, q] = JSON.parse(atob(parsed.qd.replace(/_/gi, '/').replace(/O/g, '=').replace(/~/g, 'O')))
  defaultStore.card = card
  defaultStore.navigation = navigation
  defaultStore.q = q.map(code => String.fromCharCode(code)).join('')
} catch (e) {}

const store = observable(defaultStore)

const handleSave = () => {
  const parsed = queryString.parse(window.location.search)

  const { cart, favorite, card, navigation, q, id } = toJS(store)
  localStorage.store = JSON.stringify({ cart, favorite, card, navigation, q, id })

  let _card = null
    , _navigation = null
    , _q = null

  try {
    if (parsed.qd) {
      const data = JSON.parse(atob(parsed.qd.replace(/-/gi, '+').replace(/_/gi, '/').replace(/O/g, '=').replace(/~/g, 'O')))
      _card = data[0]
      _navigation = data[1]
      _q = data[2].map(code => String.fromCharCode(code)).join('')
    }
  } catch (e) {
    console.log(e)
  }

  try {
    if ((_card !== card || _navigation !== navigation || q !== _q)) {
      let qHA = []

      if (!!q.match(/(#|→ )/)) {
        qHA = q.split('').map(data => data.charCodeAt())
      }

      window.history.pushState('', null, `?qd=${btoa(JSON.stringify([card, navigation, qHA])).replace(/\+/gi, '-').replace(/\//gi, '_').replace(/O/g, '~').replace(/=/gi, 'O')}`)
    }
  } catch (e) {
    console.log(e)
  }
}

setTimeout(() => {
  autorun(handleSave)
  setInterval(handleSave, 500)
}, 1000)

const context = createContext(store)

const useStore = () => useContext(context)

export default useStore