import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import arrowTop from './../assets/arrow-top.svg'
import heart from './../assets/heart.png'
import { observer } from 'mobx-react'
import useStore from './../store'
import { toJS } from 'mobx'
import OniAds from './oni-ads.js'
import Mc from './mc.js'
import Card from './card.js'
import CardEmpty from './card-empty.js'
import CardBig from './card-big.js'
import Cart from './cart.js'
import { motion } from 'framer-motion'

const Body = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.19rem;
  width: 100%;
`

const CardsOverflow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1.25rem;
  width: 100%;
`

const CardsColumnOverflow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.25rem;
`

const ButtonOverflow = styled(motion.div)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem;
  padding: 1.19rem 1.25rem;
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(151, 151, 151, 0.25);
  background: #fff;
  box-sizing: border-box;
  width: 100%;
`

const Icon = styled.img`
  width: 1.9rem;
  height: 1.9rem;
`

const Line = styled.div`
  width: 100%;
  height: 0.10rem;
  background: #ececec;
`

const LinkMainOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.94rem;
`

const LinkOverflow = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.94rem;
`

const Link = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: -0.03em;
  border: none;
  display: inline-block;
  color: #a5a5a5;
  cursor: pointer;
  
  &:active {
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
`

const Dot = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  border: none;
  display: inline-block;
  color: #a5a5a5;
`

const IconHeart = styled.img`
  height: 0.8rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 0.1rem;
`

const Cards = observer(() => {
  const isMain = useMediaQuery({
    query: '(min-width: 1024px)'
  })

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 670px)'
  })

  const store = useStore()

  const rowsCount = isDesktopOrLaptop ? 3 : 2

  const cards = toJS(store.cards.filter(card => store.navigation === 4 ? store.favorite.includes(card.id) : true))
                  .reduce((ctx, elem, i) => {
                      ctx[i % rowsCount] = [
                        ...ctx[i % rowsCount], 
                        elem
                      ]
                      return ctx
                  }, Array(rowsCount).fill([]))
  
  return (
    <Body>
      <CardsOverflow>
        {
          store.navigation === 3 
            ? (
              <Cart 
                style={{
                  width: isDesktopOrLaptop ? '47.5rem' : '100%', 
                  maxWidth: '100%'
                }}
              />
            ) 
            : store.card !== 0 
                ? (
                  <CardBig 
                    style={{
                      width: isDesktopOrLaptop ? '47.5rem' : '100%', 
                      maxWidth: '100%'
                    }}
                  />
                )
                : cards.flat().length === 0 
                    ? (
                      <CardEmpty
                        isFavorite={store.navigation === 4}
                        style={{
                          width: isDesktopOrLaptop ? '47.5rem' : '100%', 
                          maxWidth: '100%'
                        }}
                      />
                    )
                    : cards.map(
                        (cards, key) => {
                          return (
                            <CardsColumnOverflow 
                              key={key} 
                              style={{
                                width: isDesktopOrLaptop ? rowsCount === 3 ? '15rem' : '50%' : '100%', 
                                maxWidth: rowsCount === 3 ? '15rem' : '50%' 
                              }}>
                              {
                                cards.map((card, key) => (<Card key={key} card={card} />))
                              }
                            </CardsColumnOverflow>
                          )
                        }
                      )
        }
      </CardsOverflow>
      <Line />
      <OniAds />
      <Mc />
      {
        isMain
          ? (
            null
          )
          : (
            <ButtonOverflow whileTap={{ scale: 0.9 }} onClick={() => window.scrollTo(0, 0)}>
              <Icon draggable={false} src={arrowTop} />
            </ButtonOverflow>
          )
      }
      {

      }
      <LinkMainOverflow>
        <LinkOverflow>
          <Link onClick={() => window.location.href = 'mailto:stepfather666gang@gmail.com'}>контакты</Link>
        </LinkOverflow>
        <LinkOverflow>
          <Dot>•</Dot>
          <Link onClick={() => window.location.href = 'mailto:stepfather666gang@gmail.com'}>сотрудничество</Link>
        </LinkOverflow>
        <LinkOverflow>
          <Dot>•</Dot>
          <Link onClick={() => window.location.href = 'mailto:stepfather666gang@gmail.com'}>поддержка</Link>
        </LinkOverflow>
        <LinkOverflow>
          <Dot>•</Dot>
          <Link onClick={() => window.location.href = 'tg://resolve?domain=poterpenie'}>разместить свой мерч</Link>
        </LinkOverflow>
        <LinkOverflow>
          <Dot>•</Dot>
          <Link onClick={() => window.location.href = 'tg://resolve?domain=poterpenie'}>разработчик</Link>
        </LinkOverflow>
      </LinkMainOverflow>
      <LinkMainOverflow>
        <Link 
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            flexWrap: 'nowrap'
          }}
          onClick={() => window.location.href = 'tg://resolve?domain=poterpenie'}
        >
        <span>сделанно с</span>
        <IconHeart draggable={false} src={heart} />
        <span>клавиатуры, в 2024 году.</span></Link>
      </LinkMainOverflow>
      <div style={{ marginBottom: '0.15rem' }}></div>
    </Body>
  )
})

export default Cards